
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CommentsClient } from '@/services/Services';
import FileInput from '@/components/fileInput.vue';
import Comment from '@/components/comment.vue';
import UoseWriter from '@/components/uoseWriter.vue';
import store from '@/store';
import * as OM from '@/model';
import * as CONST from '@/const';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        FileInput,
        Comment,
        UoseWriter
    }
})
export default class CommentsModal extends Vue {

    @Prop() parentIdentifier: string;
    @Prop() grandParentIdentifier: string;
    @Prop() containerIdentifier: string;
    @Prop() commentType: string;
    @Prop() firstComment: OM.CommentVm;

    @Prop({
        default: 'COMMENTI'
    }) title: string;


    @Prop({
        default: true
    }) showReplyButton: boolean;

    vm: OM.AddCommentVm = new OM.AddCommentVm();
    isCordova: boolean = !!window.cordova;
    placeholder: string = "";

    comments: OM.CommentVm[] = [];

    created() {
        this.placeholder = LocalizationServices.getLocalizedValue("app_scriviqualcosa", "Scrivi qualcosa");

        this.vm.parentIdentifier = this.parentIdentifier;
        this.vm.grandParentIdentifier = this.grandParentIdentifier;
        this.vm.containerIdentifier = this.containerIdentifier;
        this.vm.commentType = this.commentType;
        console.log(this.vm.commentType)
        this.init();
    }

    init() {
        CommentsClient.getByParent(this.parentIdentifier)
        .then(x => {
            this.comments = x;
            if(this.firstComment){
                this.comments.unshift(this.firstComment);
            }
        })
    }

    reset: number = 0;
    send() {
        if(!this.vm.text.trim())
            return;

        store.state.globalSpinner++;
        CommentsClient.saveComment(this.vm)
        .then(x => {
            this.init();
            this.vm.text = "";
            this.reset++;
        })
        .finally(() => store.state.globalSpinner--);
    }

    reply(commentVm: OM.CommentVm) {
        if(this.firstComment){
            if(!this.vm.text.trim()){
                this.vm.text = "@" + commentVm.createdBy.name + " ";
            }
        } else {
            var parentCommentType;
            if(this.commentType == CONST.NotificationType.AllegatoCommentComment)
                parentCommentType = CONST.NotificationType.AllegatoComment;
            else if(this.commentType == CONST.NotificationType.CommunityPostCommentComment)
                parentCommentType = CONST.NotificationType.CommunityPostComment;
            else if(this.commentType == CONST.NotificationType.EventPostCommentComment)
                parentCommentType = CONST.NotificationType.EventPostComment;
            else if(this.commentType == CONST.NotificationType.FieraPostCommentComment)
                parentCommentType = CONST.NotificationType.FieraPostComment;
                
            var text = LocalizationServices.getLocalizedValue("app_rispondi", "Rispondi");

            this.$opModal.show(CommentsModal, {
                parentIdentifier: commentVm.identifier,
                containerIdentifier: this.containerIdentifier,
                title: text,
                firstComment: commentVm,
                commentType: parentCommentType,
                showReplyButton: false
            }, () => {
                CommentsClient.getSingleCommentVm(commentVm.identifier)
                .then(x => {
                    commentVm.identifier = x.identifier;
                    commentVm.createdOn = x.createdOn;
                    commentVm.text = x.text;
                    commentVm.replyCount = x.replyCount;
                    commentVm.createdBy = x.createdBy;
                    commentVm.liked = x.liked;
                })
            });
        }
    }

    deletePost(identifier: string){
        var el = this.comments.find( x => {
            return x.identifier == identifier
        })

        var index = this.comments.indexOf(el);
        this.comments.splice(index, 1);
    }

}
