
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { beforeEachCbs } from '@/router';
import { CommentsClient, ReportClient } from '@/services/Services';
import ConfirmModal from './confirmModal.vue';
import ReportCustomConfirm from './reportCustomConfirm.vue';
import ModalSuccessError from './modalSuccessError.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class Comment extends Vue {

    @Prop({
        default: true
    }) showReplyButton: boolean;

    @Prop() comment: OM.CommentVm;

    created() {
        let closeModalCb = (to, from) => {
            this.$opModal.closeLast();
            let ind = beforeEachCbs.indexOf(closeModalCb);
            beforeEachCbs.splice(ind, 1);
        };
        beforeEachCbs.push(closeModalCb);
    }

    reply() {
        this.$emit('reply', this.comment);
    }

    openPerson(item: OM.NameIdentifier) {
        if(item.identifier == store.state.user.identifier)
            return;
        
        this.$opModal.closeLast();
        this.$router.push('/profile/' + item.identifier);
    }

    get canDeleteComment() {
        return this.comment.createdBy.identifier == store.state.user.identifier || store.state.community.canSettings;
    }

    deleteComment() {
        var text = LocalizationServices.getLocalizedValue("app_vuoieliminarequestocommento", "Vuoi eliminare questo commento?");

        this.$opModal.show(ConfirmModal, {
            text: text,
            okCb: () => {
                CommentsClient.deleteComment(this.comment.identifier)
                .then( x => {
                    this.$opModal.closeLast();
                    this.$emit("deletePost");
                })
            }
        })
    }

    reportComment() {
        this.$opModal.show(ReportCustomConfirm, {
            callback: (value: string) => {
                var createReport = new OM.CreateReport();
                createReport.reportType = VM.ReportType.Comment;
                createReport.note = value;
                createReport.itemIdentifier = this.comment.identifier;
                ReportClient.createReport(createReport)
                .then( x => {
                    this.$opModal.closeLast();
                    var text = LocalizationServices.getLocalizedValue("app_commentosegnalatoconsuccesso", "Commento segnalato con successo!");

                    this.$opModal.show(ModalSuccessError, {
                        message: text,
                    })
                })
            }
        })
    }

}
