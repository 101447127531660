
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { setCaretLast, decodeHtml, getCaretPosition } from '@/utils';
import { CommunityClient } from '@/services/Services';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';


@Options({})
export default class UoseWriter extends Vue {

    @Prop() modelValue: string;
    @Prop() reset: number;
    @Prop() placeholderText: string;
    @Prop() placeholderKey: string;
    @Prop() minHeight: number;
    @Prop() maxHeight: number;

    localText: string = "";

    textarea: any;
    mounted() {
        this.textarea = this.$refs.textarea;
        if(this.modelValue)
            this.textarea.innerHTML = this.modelValue;

        this.searchCommunities("");
    }

    @Watch('reset')
    onValueChange(next, prev){
        if(next){
            this.localText = "";
            this.textarea.innerHTML = "";
        }
    }

    get placeholder(){
        return LocalizationServices.getLocalizedValue(this.placeholderKey, this.placeholderText);
    }

    setLocalText(ev){
        this.localText = decodeHtml(ev.target.innerHTML);
        this.$emit('update:modelValue', this.localText);

        if(ev.data == '&')
            this.eCommercialeTriggered();
        else if(ev.which == 13)
            this.enterTriggered(ev);
    }
    hideSearch() {
        document.getElementById("app").classList.remove("chatSearchCommunitiesOpened");
        setTimeout(() => {
            this.search = "";
            this.showSearch = false;
            setCaretLast(this.textarea);
        }, 100)
    }

    showSearch: boolean = false;
    search: string = "";
    debouncia: any;
    @Watch('search')
    onSearchChange(next, prev){
        clearTimeout(this.debouncia);
        this.debouncia = setTimeout(() => {
            this.searchCommunities(next);
        }, 300);
    }
    doRec: boolean = false;
    recbuffer: string = "";
    insertPosition: number;
    checkLetter(ev){
        // ev.key == '&' - ev.keyCode == 13
        if(ev.key == '&')
            this.eCommercialeTriggered();
        else if(ev.which == 13)
            this.enterTriggered(ev);
    }

    eCommercialeTriggered() {
        this.setShowSearch();
        this.insertPosition = getCaretPosition(this.textarea);
        setTimeout(() => {
            (<any>this.$refs.searchInput).focus();
        }, 100);
    }
    enterTriggered(ev) {
        // insert 2 br tags (if only one br tag is inserted the cursor won't go to the second line)
        document.execCommand('insertHTML', false, '<br><br>');
        // prevent the default behaviour of return key pressed
        ev.preventDefault();
        return false;
    }

    chatSearchCommunities: OM.SearchResultVm[] = [];
    searchCommunities(query){
        CommunityClient.chatSearch(query)
        .then(x => {
            this.chatSearchCommunities = x;
        })
    }

    async setCommunity(item: OM.SearchResultVm) {
    this.search = "";

        try {
                const community = await CommunityClient.getById(item.identifier);

                // controllo se ce dlug e titol
                if (community && community.slug && community.title) {
                    // url per evitare il push
                    const customUrl = `/communities/${community.slug}/`;
                    // Aggiorna la stringa this.localText con l'URL personalizzato
                    this.localText = this.localText.slice(0, this.insertPosition - 1) +
                        `<a href='${customUrl}' target="_self">${community.title}</a> ` +
                        this.localText.slice(this.insertPosition + 1);

                    
                    this.textarea.innerHTML = this.localText;

                    console.log(this.localText);

                    
                    this.$emit('update:modelValue', this.localText);
                } else {
                    console.error('Informazioni sulla comunità non valide:', community);
                }
            } catch (error) {
                console.error('Errore durante la chiamata API per ottenere la comunità:', error);
        }

        // nascondi la ricerca
        this.hideSearch();
    }

    setShowSearch(){
        this.showSearch = true;
        document.getElementById("app").classList.add("chatSearchCommunitiesOpened");
        // this.$nextTick(() => {
        //     let root = this.textarea;
        //     let container = <HTMLElement>this.$refs.chatSearchContainer;
        //     let rect = container.getBoundingClientRect();
        //     if(rect.x > window.innerWidth / 2){
        //         container.style.right = "0";
        //     } else {
        //         container.style.left = "0";
        //     }
        //     let height = root.getBoundingClientRect().height;
        //     let toAdd = 40;
        //     if(height > 80){
        //         height = 80;
        //         toAdd = 20;
        //     }
        //     if(rect.y > window.innerHeight / 2){
        //         container.style.bottom = height + toAdd + "px";
        //     } else {
        //         container.style.top = height + toAdd + "px";
        //     }
        // })
    }
}

