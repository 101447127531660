
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ReportCustomConfirm extends Vue {

    @Prop() callback: (value: string) => void;

    note: string = "";
    returned: boolean = false;

    confirm(){
        this.callback(this.note);
    }

    get disabled() {
        return !this.note.trim()
    }

}
